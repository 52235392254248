import {defineNuxtRouteMiddleware, extractQueryParameters, guestHost} from '#imports';
import {navigateTo} from '@typed-router';
import type {RouteLocationNormalized} from 'vue-router';

import {getMaybeRedirection} from '~/middleware/redirect/redirection';

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized) => {
    const host = guestHost();
    const queryParams = extractQueryParameters(to) || '';

    const maybeRedirection = getMaybeRedirection(to, host, queryParams);
    if (maybeRedirection.redirect && maybeRedirection.url) {
        return navigateTo(maybeRedirection.url, {external: true});
    }
});
